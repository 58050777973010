.card {
    .cardContent {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }
    .cardTitle {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }

    p,
    li {
        a {
            text-decoration: underline;
            &:hover {
                font-weight: 600;
            }
        }
    }

    li {
        list-style-type: disc;
        list-style-position: outside;
        margin-left: 18px;
    }

    iframe {
        width: 100%;
    }
}
.cardWrapper {
    @media (min-width: 640px) {
        &:hover {
            .category {
                img :global {
                    animation-name: icon;
                    animation-duration: 1s;
                    animation-timing-function: cubic-bezier(0.51, 0.92, 0.24, 1.15);
                    // animation-delay: .5s;
                    animation-iteration-count: infinite;
                    animation-fill-mode: forwards;
                    animation-direction: alternate;
                }
            }
        }
    }
}
